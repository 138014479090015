
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<template>
  <div class="flex flex-col min-h-screen">
    <main class="flex-1 p-4">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div class="bg-white rounded-lg shadow-lg p-4">
          <p class="text-gray-700">
            <RecordingCard @data-received="handleData" />
          </p>
        </div>

        <div class="bg-white rounded-lg shadow-lg p-4">
          <h2 class="text-gray-800 text-lg font-bold mb-2">
            录译结果
          </h2>
          <div class="text-gray-700">
            <div class="relative">
              <textarea
                class="w-full h-60 border border-gray-400 rounded-md py-2 pl-4 pr-4 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                placeholder="录音翻译结果" v-model="transcript"></textarea>
              <div class="absolute top-0 right-0 px-3 py-2" @click="copyToClipboard">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M4 5h5.5a2.5 2.5 0 0 1 2.5 2.5V11h3.5a2.5 2.5 0 0 1 2.5 2.5v5M14 15H9.5a2.5 2.5 0 0 1-2.5-2.5V13H3a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v7a2 2 0 0 1-2 2h-3.5">
                  </path>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white rounded-lg shadow-lg p-4">
          <button class="bg-gray-500 hover:bg-gray-700 text-white font-light py-2 px-4 rounded-md" @click="getAiAnswer">
            AI优化
          </button>

          <div class="text-gray-700 mt-4">
            <input type="text" class="w-full border rounded-lg py-2 px-4 block" v-model="aiInstruction">

            <div class="relative mt-6">
              <textarea
                class="w-full h-60 border border-gray-400 rounded-md py-2 pl-4 pr-4 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                placeholder="AI优化结果" v-model="transcriptRevised"></textarea>
              <div class="absolute top-0 right-0 px-3 py-2" @click="copyToClipboardRevised">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M4 5h5.5a2.5 2.5 0 0 1 2.5 2.5V11h3.5a2.5 2.5 0 0 1 2.5 2.5v5M14 15H9.5a2.5 2.5 0 0 1-2.5-2.5V13H3a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v7a2 2 0 0 1-2 2h-3.5">
                  </path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer class="bg-gray-200 mt-auto">
      <p class="text-white text-center"> © 2023</p>
      <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备19024464号-3</a>
    </footer>
  </div>
</template>

 
<script>
import RecordingCard from './components/RecordingCard.vue';
const isDev = !true;
const api = isDev ? 'https://ngrok.shipma.com/wechat-oa/get-answer' : 'https://bff.searchwithgpt.com/wechat-oa/get-answer';

export default {
  name: 'App',
  components: {
    RecordingCard,
  },

  props: ['textValue'],
  data() {
    return {
      transcript: this.textValue,
      transcriptRevised: '',
      aiInstruction: 'You are an English teacher, and I am a non-native English speaker. Revise and correct below content, which is following the : sign, written by me: ',
    }
  },
  watch: {
    transcript(newVal) {
      this.$emit('textValue', newVal);
    }
  },

  methods: {
    getAiAnswer() {
      const data = {
        question: this.aiInstruction + ' ' + this.transcript,
      };
      fetch(api, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.text())
        .then((data) => {
          this.transcriptRevised = data;
        })
        .catch((error) => {
          console.error('Error:', error);
          this.transcriptRevised = 'Error';
        });
    },

    handleData(data) {
      this.transcript = data;
    },
    copyToClipboard(e) {
      console.log({ e });
      navigator.clipboard.writeText(this.transcript);
    },
    copyToClipboardRevised(e) {
      console.log({ e });
      navigator.clipboard.writeText(this.transcriptRevised);
    },
  }

};


</script>
