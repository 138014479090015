<template>
  <div class="flex flex-col space-y-4">
    <button
      class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
      @click="recStart"
      v-if="!isRecording"
    >
      开始
    </button>

    <button
      class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full"
      @click="recStop"
      v-if="isRecording"
    >
      停止
    </button>

    <div class="flex space-x-4">
      <button
        class="bg-blue-200 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded"
        @click="recOpen"
      >
        请求权限
      </button>
      <button
        class="bg-blue-200 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded"
        @click="recClose"
      >
        释放权限
      </button>
    </div>

    <div class="flex flex-row justify-between h-20">
      <div class="border border-gray-400 w-3/4 ctrlProcessWave">
        <!-- placerholder for wave -->
      </div>

      <div
        class="border-none flex-1 px-4 relative flex flex-col ctrlProcessWave"
      >
        <div
          class="ctrlProcessX"
          style="height: 40px; background: #0b1"
          :style="{ width: powerLevel + '%' }"
        ></div>
        <div class="ctrlProcessT" style="line-height: 40px">
          {{ duration + '/' + powerLevel }}
        </div>
      </div>
    </div>

    <div class="recordingResult flex flex-col">
      <div class="h-12 w-full mb-6">
        <audio ref="LogAudioPlayer" class="w-full"></audio>
      </div>

      <ul class="mainLog list-none block text-left">
        <li v-for="obj in logs" :key="obj.idx">
          <div
            :style="{
              color:
                obj.color == 1 ? 'red' : obj.color == 2 ? 'green' : obj.color,
            }"
          >
            <!-- <template v-once> 在v-for里存在的bug，参考：https://v2ex.com/t/625317 -->
            <span v-once>[{{ getTime() }}]</span><span v-html="obj.msg" />

            <template v-if="obj.res">
              {{ intp(obj.res.rec.set.bitRate, 3) }}kbps
              {{ intp(obj.res.rec.set.sampleRate, 5) }}hz 编码{{
                intp(obj.res.blob.size, 6)
              }}b [{{ obj.res.rec.set.type }}]{{ intp(obj.res.duration, 6) }}ms

              <div class="inline-flex">
                <button
                  class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  @click="recdown(obj.idx)"
                >
                  下载
                </button>
                <div class="w-10"></div>
                <button
                  class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                  @click="recplay(obj.idx)"
                >
                  播放
                </button>

                <div class="w-10"></div>
                <button
                  class="bg-lime-500 hover:bg-lime-700 text-white font-bold py-2 px-4 rounded"
                  @click="recUploadLast"
                >
                  上传录译
                </button>
              </div>

              <span v-html="obj.playMsg"></span>
              <span v-if="obj.down">
                <span class="text-red-500">{{ obj.down }}</span>
                没弹下载？试一下链接或复制文本<button
                  @click="recdown64(obj.idx)"
                >
                  生成Base64文本
                </button>
                <textarea
                  v-if="obj.down64Val"
                  v-model="obj.down64Val"
                ></textarea>
              </span>
            </template>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
//加载必须要的core，demo简化起见采用的直接加载类库，实际使用时应当采用异步按需加载
import Recorder from 'recorder-core';
//需要使用到的音频格式编码引擎的js文件统统加载进来，这些引擎文件会比较大
import 'recorder-core/src/engine/mp3';
import 'recorder-core/src/engine/mp3-engine';
//可选的扩展
import 'recorder-core/src/extensions/waveview';

const isDev = !true;
const api = isDev ? 'https://ngrok.shipma.com/file/transcribe' : 'https://bff.searchwithgpt.com/file/transcribe';

export default {


  data() {
    return {
      isRecording: false,
      Rec: Recorder,
      type: 'mp3',
      bitRate: 16,
      sampleRate: 16000,
      rec: 0,
      duration: 0,
      powerLevel: 0,
      recOpenDialogShow: 0,
      logs: [],
    };
  },
  methods: {
    recOpen: function () {
      var that = this;
      var rec = (this.rec = Recorder({
        type: that.type,
        bitRate: that.bitRate,
        sampleRate: that.sampleRate,
        onProcess: function (buffers, powerLevel, duration, sampleRate) {
          that.duration = duration;
          that.powerLevel = powerLevel;
          that.wave.input(buffers[buffers.length - 1], powerLevel, sampleRate);
        },
      }));
      that.dialogInt = setTimeout(function () {
        //定时8秒后打开弹窗，用于监测浏览器没有发起权限请求的情况
        that.showDialog();
      }, 8000);
      rec.open(
        function () {
          that.dialogCancel();
          that.reclog(
            '已打开:' +
              that.type +
              ' ' +
              that.sampleRate +
              'hz ' +
              that.bitRate +
              'kbps',
            2
          );

          that.wave = Recorder.WaveView({ elem: '.ctrlProcessWave' });
        },
        function (msg, isUserNotAllow) {
          that.dialogCancel();
          that.reclog(
            (isUserNotAllow ? 'UserNotAllow，' : '') + '打开失败：' + msg,
            1
          );
        }
      );
      that.waitDialogClickFn = function () {
        that.dialogCancel();
        that.reclog('打开失败：权限请求被忽略，用户主动点击的弹窗', 1);
      };
    },
    recClose: function () {
      var rec = this.rec;
      this.rec = null;
      if (rec) {
        rec.close();
        this.reclog('已关闭');
      } else {
        this.reclog('未打开录音', 1);
      }
    },
    recStart: function () {
      let that = this;
      if (!that.rec || !Recorder.IsOpen()) {
        that.reclog('未打开录音', 1);
        return;
      }
      that.isRecording = true;
      that.rec.start();
      var set = that.rec.set;
      that.reclog(
        '录制中：' +
          set.type +
          ' ' +
          set.sampleRate +
          'hz ' +
          set.bitRate +
          'kbps'
      );
      console.log(that);
    },
    recPause: function () {
      if (this.rec && Recorder.IsOpen()) {
        this.rec.pause();
      } else {
        this.reclog('未打开录音', 1);
      }
    },
    recResume: function () {
      if (this.rec && Recorder.IsOpen()) {
        this.rec.resume();
      } else {
        this.reclog('未打开录音', 1);
      }
    },
    recStop: function () {
      var that = this;
      var rec = that.rec;
      if (!(this.rec && Recorder.IsOpen())) {
        that.reclog('未打开录音', 1);
        return;
      }
      that.isRecording = false;
      rec.stop(
        function (blob, duration) {
          that.reclog('已录制:', '', {
            blob: blob,
            duration: duration,
            rec: rec,
          });
        },
        function (s) {
          that.reclog('录音失败：' + s, 1);
        }
      );
    },

    recPlayLast: function () {
      if (!this.recLogLast) {
        this.reclog('请先录音，然后停止后再播放', 1);
        return;
      }
      this.recplay(this.recLogLast.idx);
    },
    recUploadLast: function () {
      if (!this.recLogLast) {
        this.reclog('请先录音，然后停止后再上传', 1);
        return;
      }
      var that = this;
      var blob = this.recLogLast.res.blob;

      //本例子假设使用原始XMLHttpRequest请求方式，实际使用中自行调整为自己的请求方式
      //录音结束时拿到了blob文件对象，可以用FileReader读取出内容，或者用FormData上传
      var onreadystatechange = function (title) {
        return function () {
          console.log({xhr});
          if (xhr.readyState == 4) {
            if (xhr.status == 200 || xhr.status == 201) {
              that.reclog(title + '上传成功', 2);
              that.$emit('data-received', (xhr?.responseText));
            } else {
              that.reclog(title, '#d8c1a0');

              console.error(title + '上传失败', xhr.status, xhr.responseText);
            }
          }
        };
      };
      that.reclog('开始上传到' + api + '，请求稍后...', '#f60');
      /***方式二：使用FormData用multipart/form-data表单上传文件***/
      var form = new FormData();
      form.append('file', blob, 'recorder.mp3'); //和普通form表单并无二致，后端接收到upfile参数的文件，文件名为recorder.mp3
      //...其他表单参数

      var xhr = new XMLHttpRequest();
      xhr.open('POST', api);
      xhr.onreadystatechange = onreadystatechange('上传方式二【FormData】');
      xhr.send(form);
    },
    reclog: function (msg, color, res) {
      var obj = {
        idx: this.logs.length,
        msg: msg,
        color: color,
        res: res,
        playMsg: '',
        down: 0,
        down64Val: '',
      };
      if (res && res.blob) {
        this.recLogLast = obj;
      }
      this.logs.splice(0, 0, obj);
    },
    recplay: function (idx) {
      var that = this;
      var o = this.logs[this.logs.length - idx - 1];
      o.play = (o.play || 0) + 1;
      var logmsg = function (msg) {
        o.playMsg =
          '<span style="color:green">' +
          o.play +
          '</span> ' +
          that.getTime() +
          ' ' +
          msg;
      };
      logmsg('');
      var audio = this.$refs.LogAudioPlayer;
      audio.controls = true;
      if (!(audio.ended || audio.paused)) {
        audio.pause();
      }
      audio.onerror = function () {
        logmsg(
          '<span style="color:red">播放失败[' +
            audio.error.code +
            ']' +
            audio.error.message +
            '</span>'
        );
      };
      audio.src = (window.URL || window.webkitURL).createObjectURL(o.res.blob);
      audio.play();
    },
    recdown: function (idx) {
      var that = this;
      var o = that.logs[that.logs.length - idx - 1];
      o.down = (o.down || 0) + 1;
      o = o.res;
      var name =
        'rec-' +
        o.duration +
        'ms-' +
        (o.rec.set.bitRate || '-') +
        'kbps-' +
        (o.rec.set.sampleRate || '-') +
        'hz.' +
        (o.rec.set.type || (/\w+$/.exec(o.blob.type) || [])[0] || 'unknown');
      var downA = document.createElement('A');
      downA.href = (window.URL || window.webkitURL).createObjectURL(o.blob);
      downA.download = name;
      downA.click();
    },
    recdown64: function (idx) {
      var that = this;
      var o = that.logs[that.logs.length - idx - 1];
      var reader = new FileReader();
      reader.onloadend = function () {
        o.down64Val = reader.result;
      };
      reader.readAsDataURL(o.res.blob);
    },
    getTime: function () {
      var now = new Date();
      var t =
        ('0' + now.getHours()).substr(-2) +
        ':' +
        ('0' + now.getMinutes()).substr(-2) +
        ':' +
        ('0' + now.getSeconds()).substr(-2);
      return t;
    },
    intp: function (s, len) {
      s = s == null ? '-' : s + '';
      if (s.length >= len) return s;
      return ('_______' + s).substr(-len);
    },
    showDialog: function () {
      //我们可以选择性的弹一个对话框：为了防止移动端浏览器存在第三种情况：用户忽略，并且（或者国产系统UC系）浏览器没有任何回调
      if (!/mobile/i.test(navigator.userAgent)) {
        return; //只在移动端开启没有权限请求的检测
      }
      this.recOpenDialogShow = 1;
    },
    dialogCancel: function () {
      clearTimeout(this.dialogInt);
      this.recOpenDialogShow = 0;
    },
    waitDialogClick: function () {
      this.dialogCancel();
      this.waitDialogClickFn();
    },
  },
};
</script>
